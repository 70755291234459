import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Kipping (Bar & Ring Movements, HSPUs)`}</p>
    <p>{`then,`}</p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`200M Run`}</p>
    <p>{`10 Pullups`}</p>
    <p>{`15 Pushups`}</p>
    <p>{`20 Jump Squats`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`16 Rounds of Tabata `}<em parentName="p">{`(:20 on, :10 off. Alternating between the two
movements.)`}</em></p>
    <p>{`Situps`}</p>
    <p>{`Planks`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      